export const INPUT_TYPE_PASSWORD = 'password';
export const INPUT_TYPE_TEXT = 'text';
export const EMAIL_PLACEHOLDER = 'name@organisation.com';
export const EMAIL_SCREEN = 'email';
export const PASSWORD_SCREEN = 'password';
export const COMBINED_SCREEN = 'combined';
export const FORGOT_PASSWORD_SCREEN = 'forgot_password';

export const validate = async ({ email, locale, return_to }) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const response = await fetch(
    '/validate',
    {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user: { email }, locale, return_to }),
    }
  );

  const data = await response.json();
  const status = response.status;

  return { ...data, status };
};

export const sendMagicLink = async ({ email, locale, return_to }) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const response = await fetch(
    '/send_magic_link',
    {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user: { email }, locale, return_to }),
    }
  );

  const data = await response.json();
  const status = response.status;

  return { ...data, status };
};

export const login = async ({ email, password, locale, return_to }) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const response = await fetch(
    '/login',
    {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user: { email, password }, locale, return_to }),
    }
  );

  const data = await response.json();
  const status = response.status;

  return { ...data, status };
};

export const sendResetLink = async ({ email, locale, return_to }) => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const response = await fetch(
    '/users/password',
    {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user: { email }, locale, return_to }),
    }
  );

  const data = await response.json();
  const status = response.status;

  return { ...data, status };
};
