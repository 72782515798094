import { Row } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { Button, BUTTON_THEME } from '_Elements/button/v2';
import { SVGLogout } from '_Icons/menu';

const MagicLinkSent = (props) => {
  const { return_to, messages } = props;

  let link = '/login';
  if (return_to) link = `${link}?return_to=${return_to}`;

  return (
    <>
      <Row center>
        <SVGLogo size={64} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5}>{messages['magic_link_expired']}</H>
      </Row>
      <Row center style={{ marginBlockStart: 24 }}>
        <P textSize={PARAGRAPH_SIZES.P1}>{messages['return_to_login']}</P>
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <Button
          trailingIcon={<SVGLogout size={22} />}
          rounded
          theme={BUTTON_THEME.PRIMARY}
          href={link}
        >
          {messages['go_to_log_in']}
        </Button>
      </Row>
    </>
  );
};

export default MagicLinkSent;
