import { useCallback } from 'react';
import { Row, RowItem } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGNext } from '_Icons/main';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { Button, BUTTON_THEME } from '_Elements/button/v2';

import EmailPassCodeBox from './EmailPassCodeBox';
import PasswordBox from './PasswordBox';
import MagicLink from './MagicLink';
import { login, validate } from './Utils';

const EmailPassCodePanel = props => {
  const {
    passcode_prompt,
    loading,
    locked,
    messages,
    return_to,
    locale,
    dir,
    onButtonClick,
    onChangeEmail,
    onChangePassword,
    email,
    password,
    error,
    setState,
  } = props;

  const validateInput = useCallback(
    async ({ email, locale, return_to }) => {
      // validate the email address / pass code
      try {
        const data = await validate({ email, locale, return_to });
        const { status, error } = data;

        if (status != 200) return { error };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const loginUser = useCallback(
    async ({ email, locale, return_to }) => {
      try {
        const data = await login({ email, locale, return_to });
        const { status, error } = data;

        if (status != 200) return { error: error || messages['unknown'] };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const onClick = useCallback(
    async () => {
      if (!email) return setState({ error: messages['field_not_supplied'] });

      setState({ error: undefined, passcode_prompt: undefined, loading: true });

      const { error: validationError, email: validEmail, passcode: validPasscode, location, locked } = await validateInput({ email, locale, return_to });

      if (validationError) return setState({ error: validationError, locked, loading: false });

      if (validEmail) {
        setState({ loading: false });

        if (location) {
          return window.location.href = location;
        }

        return onButtonClick({ email, password }); // the user entered an email, not a pass code
      }

      if (validPasscode) {
        const { error: loginError, location } = await loginUser({ email, locale, return_to });

        if (loginError) return setState({ error: loginError, loading: false });

        return window.location.href = location;
      }
    },
    [email, password, locale, return_to, onButtonClick, setState, validateInput, loginUser, messages]
  );

  return (
    <div className={'login-portal__input'} data-component={'email-pass-code-panel'} dir={dir}>
      <Row center>
        <SVGLogo size={64} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5}>{messages['log_in']}</H>
      </Row>
      <Row style={{ marginBlockStart: 40 }}>
        <P textSize={PARAGRAPH_SIZES.P3} style={{ marginBlockEnd: 4 }}>{messages['email_or_passcode']}</P>
      </Row>
      <EmailPassCodeBox
        dir={dir}
        email={email}
        onChange={onChangeEmail}
        onClick={onClick}
      />
      <PasswordBox
        dir={dir}
        onChange={onChangePassword}
        visible={false}
      />
      <Row style={{ alignItems: 'start', height: 20, marginBlockStart: 4, minHeight: 65 }}>
        {error ? (
          <P textSize={PARAGRAPH_SIZES.P3} style={{ color: SYSTEM_COLOR.CHART.RED }}>{error}</P>
        ) : (
          <>
            <P textSize={PARAGRAPH_SIZES.P3}>{passcode_prompt}</P>
          </>
        )}
      </Row>
      {locked ? (
        <Row justifyContent={'space-between'} style={{ marginBlockStart: 12 }}>
          <RowItem>
            <MagicLink
              messages={messages}
              email={email}
              locale={locale}
              return_to={return_to}
              setState={setState}
            />
          </RowItem>
          <RowItem>
            <Button
              trailingIcon={<SVGNext size={22} />}
              rounded
              theme={BUTTON_THEME.PRIMARY}
              onClick={onClick}
              loading={loading}
            >
              {messages['next_bare']}
            </Button>
          </RowItem>
        </Row>
      ) : (
        <Row justifyContent={'flex-end'} style={{ marginBlockStart: 12 }}>
          <Button
            trailingIcon={<SVGNext size={22} />}
            rounded
            theme={BUTTON_THEME.PRIMARY}
            onClick={onClick}
            loading={loading}
          >
            {messages['next_bare']}
          </Button>
        </Row>
      )}
    </div>
  );
};

export default EmailPassCodePanel;
