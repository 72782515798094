import { useCallback } from 'react';
import { useStateReducer } from '_Hooks';
import { Row, RowItem } from '_Elements/layout';
import { SVGLogo, SVGArrowSolid } from '_Icons';
import { SVGLogout } from '_Icons/menu';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { Button, BUTTON_THEME } from '_Elements/button/v2';
import { Link } from '_Elements/text';

import EmailPassCodeBox from './EmailPassCodeBox';
import { sendResetLink, INPUT_TYPE_PASSWORD } from './Utils';

const ForgotPasswordPanel = (props) => {
  const {
    messages,
    return_to,
    dir,
    locale,
    onNavButtonClick,
    onChangeEmail,
    email,
    prev,
  } = props;

  const [state, setState] = useStateReducer(
    () => ({
      error: undefined,
      inputType: INPUT_TYPE_PASSWORD,
      loading: false,
    })
  );
  const { error, loading } = state;

  const doForgotPassword = useCallback(
    async ({ email, locale, return_to }) => {
      try {
        const data = await sendResetLink({ email, locale, return_to });
        const { status, error } = data;

        if (status != 200) return { error: error || messages['unknown'] };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const onClick = useCallback(
    async () => {
      if (!email) return setState({ error: messages['field_not_supplied'] });

      setState({ error: undefined, loading: true });

      const { error: loginError, location } = await doForgotPassword({ email, locale, return_to });

      if (loginError) return setState({ error: loginError, loading: false });
      metrics.track('Sign in - Forgot password - Password reset email sent');
      return window.location.href = location;
    },
    [setState, email]
  );

  return (
    <div className={'login-portal__input'}>
      <Row center style={{ color: SYSTEM_COLOR.PRIMARY, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Link
          className={'login-portal__link'}
          style={{ fontSize: 14 }}
          openInNewTab={false}
          color={SYSTEM_COLOR.PRIMARY}
          onClick={onNavButtonClick(prev)}
        >
          <SVGArrowSolid
            size={14}
            rotate={90}
            style={{ marginInlineEnd: 8 }}
          />
        </Link>
        <SVGLogo
          size={64}
        />
        <span style={{ width: 14, visibility: 'hidden' }} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5}>{messages['forgot_your_password']}</H>
      </Row>
      <Row center style={{ marginBlockStart: 24 }}>
        <P textSize={PARAGRAPH_SIZES.P1}>{messages['thats_ok']}</P>
      </Row>
      <Row center>
        <P textSize={PARAGRAPH_SIZES.P1} style={{ textAlign: 'center' }}>{messages['we_can_send_reset_link']}</P>
      </Row>
      <Row style={{ marginBlockStart: 40 }}>
        <P textSize={PARAGRAPH_SIZES.P3} style={{ marginBlockEnd: 4 }}>{messages['enter_your_email']}</P>
      </Row>
      <EmailPassCodeBox
        dir={dir}
        email={email}
        onChange={onChangeEmail}
        onClick={onClick}
      />
      <Row style={{ alignItems: 'start', height: 20, marginBlockStart: 4, minHeight: 65 }}>
        {error && (
          <P textSize={PARAGRAPH_SIZES.P3} style={{ color: SYSTEM_COLOR.CHART.RED }}>{error}</P>
        )}
      </Row>
      <Row style={{ marginBlockStart: 12 }}>
        <RowItem right>
          <Button
            trailingIcon={<SVGLogout size={22} />}
            rounded
            theme={BUTTON_THEME.PRIMARY}
            onClick={onClick}
            loading={loading}
          >
            {messages['send_reset_link']}
          </Button>
        </RowItem>
      </Row>
    </div>
  );
};

export default ForgotPasswordPanel;
