import { useCallback } from 'react';
import { useStateReducer } from '_Hooks';
import { Row, RowItem } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGLogout } from '_Icons/menu';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { ComboBox } from '_Elements/select';
import { Button, BUTTON_THEME } from '_Elements/button/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';

const EmailLink = ({ email, text, style }) => {
  return (
    <a href={`mailto:${email}`} style={style} rel={'nofollow noopener'}>{text}</a>
  );
};

const AccountSelect = (props) => {
  const { messages, options, value: propsValue, support_email, logout_path } = props;

  const [state, setState] = useStateReducer(
    () => ({
      value: propsValue,
      loading: false,
    })
  );
  const { value, loading } = state;

  const onChange = useCallback(
    value => setState({ value }),
    [setState]
  );
  const onClick = useCallback(
    () => {
      setState({ loading: true });
      document.location.href = value;
    },
    [value, setState]
  );

  return (
    <>
      <Row center>
        <SVGLogo
          size={64}
        />
      </Row>
      {options.length ? (
        <>
          <Row center style={{ marginBlockStart: 40 }}>
            <H textSize={HEADING_SIZES.H5} style={{ textAlign: 'center' }}>{messages['which_organisation']}</H>
          </Row>
          <Row style={{ marginBlockStart: 40 }}>
            <ComboBox
              label={messages['select_organisation']}
              options={options}
              value={value}
              onChange={onChange}
              aria-label={messages['select_organisation']}
            />
          </Row>
          <Row style={{ marginBlockStart: 40, justifyContent: 'end' }}>
            <Button
              trailingIcon={<SVGLogout size={22} />}
              rounded
              theme={BUTTON_THEME.PRIMARY}
              onClick={onClick}
              loading={loading}
            >
              {messages['go_to_account']}
            </Button>
          </Row>
        </>
      ) : (
        <>
          <Row center style={{ marginBlockStart: 40 }}>
            <H textSize={HEADING_SIZES.H5} style={{ textAlign: 'center' }}>{messages['you_do_not_have_accounts']}</H>
          </Row>
          <Row center style={{ marginBlockStart: 40 }}>
            <Button
              rounded
              theme={BUTTON_THEME.PRIMARY}
              href={logout_path}
            >
              {messages['log_out']}
            </Button>
          </Row>
          <Row center style={{ marginBlockStart: 36 }}>
            <RowItem>
              <P textSize={PARAGRAPH_SIZES.P2}>
                {messages['having_trouble']}
              </P>
              &nbsp;
              <P textSize={PARAGRAPH_SIZES.P2}>
                <EmailLink
                  email={support_email}
                  text={messages['get_help']}
                  style={{ color: SYSTEM_COLOR.CHART.RED }}
                />
              </P>
            </RowItem>
          </Row>
        </>
      )}
    </>
  );
};

export default AccountSelect;
