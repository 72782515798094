import { useEffect, useRef } from 'react';
import { Row } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGLoadingSpinner } from '_Icons/loading';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';

const NewSSO = (props) => {
  const { organisation_name, location, authenticity_token, messages } = props;
  const ref = useRef();
  useEffect(
    () => {
      setTimeout(
        () => ref.current.click(),
        3000
      );
    },
    []
  );

  return (
    <>
      <Row center>
        <SVGLogo size={64} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5} style={{ textAlign: 'center' }}>{organisation_name}</H>
      </Row>
      <Row center>
        <H textSize={HEADING_SIZES.H5} style={{ textAlign: 'center' }}>{messages['is_using_sso']}</H>
      </Row>
      <Row center style={{ marginBlockStart: 24 }}>
        <P textSize={PARAGRAPH_SIZES.P1}>{messages['we_are_logging_you_in']}</P>
      </Row>
      <Row center style={{ marginBlockStart: 42 }}>
        <SVGLoadingSpinner size={32} color={SYSTEM_COLOR.CHART.RED} />
      </Row>
      <form style={{ display: 'none' }} method={'post'} action={location}>
        <input type={'hidden'} name={'authenticity_token'} value={authenticity_token} />
        <button type={'submit'} ref={ref} />
      </form>
    </>
  );
};

export default NewSSO;
