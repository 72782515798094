import { useCallback } from 'react';
import { useStateReducer } from '_Hooks';

import EmailPassCodePanel from './EmailPassCodePanel';
import PasswordPanel from './PasswordPanel';
import ForgotPasswordPanel from './ForgotPasswordPanel';
import CombinedLoginPanel from './CombinedLoginPanel';
import { COMBINED_SCREEN, EMAIL_SCREEN, PASSWORD_SCREEN, FORGOT_PASSWORD_SCREEN, INPUT_TYPE_PASSWORD } from './Utils';

const LoginForm = (props) => {
  const { error: propsError, messages, locale, dir, email: propsEmail, return_to } = props;
  const [state, setState] = useStateReducer(
    () => ({
      screen: EMAIL_SCREEN,
      loading: false,
      locked: false,
      passcode_prompt: messages['passcode_prompt'],
      inputType: INPUT_TYPE_PASSWORD,
      email: propsEmail,
      password,
      error: propsError,
    })
  );
  const { screen, loading, locked, passcode_prompt, inputType, email, password, error } = state;

  const onEmailInputChange = useCallback(
    email => setState({ email }),
    [setState]
  );

  const onPasswordInputChange = useCallback(
    password => setState({ password }),
    [setState]
  );

  const onEmailButtonClick = useCallback(
    ({ email, password }) => setState({ email, password, screen: PASSWORD_SCREEN }),
    [setState]
  );

  const onNavButtonClick = useCallback(
    screen => () => setState({ screen }),
    [setState]
  );

  const onClickForgotPassword = useCallback(
    () => {
      setState({ screen: FORGOT_PASSWORD_SCREEN });
      metrics.track('Sign in - Forgot password - Forgot password button clicked');
    },
    [setState]
  );

  if (screen == EMAIL_SCREEN) return (
    <EmailPassCodePanel
      loading={loading}
      locked={locked}
      passcode_prompt={passcode_prompt}
      messages={messages}
      return_to={return_to}
      locale={locale}
      dir={dir}
      onButtonClick={onEmailButtonClick}
      onChangeEmail={onEmailInputChange}
      onChangePassword={onPasswordInputChange}
      email={email}
      password={password}
      error={error}
      setState={setState}
    />
  );

  if (screen == PASSWORD_SCREEN) return (
    <PasswordPanel
      loading={loading}
      messages={messages}
      return_to={return_to}
      locale={locale}
      dir={dir}
      onNavButtonClick={onNavButtonClick}
      onChangeEmail={onEmailInputChange}
      onChangePassword={onPasswordInputChange}
      onClickForgotPassword={onClickForgotPassword}
      email={email}
      password={password}
      inputType={inputType}
      prev={EMAIL_SCREEN}
      error={error}
      setState={setState}
      screen={screen}
    />
  );

  if (screen == COMBINED_SCREEN) return (
    <CombinedLoginPanel
      loading={loading}
      messages={messages}
      return_to={return_to}
      locale={locale}
      dir={dir}
      onNavButtonClick={onNavButtonClick}
      onChangeEmail={onEmailInputChange}
      onChangePassword={onPasswordInputChange}
      onClickForgotPassword={onClickForgotPassword}
      email={email}
      password={password}
      inputType={inputType}
      prev={EMAIL_SCREEN}
      error={error}
      setState={setState}
    />
  );

  return (
    <ForgotPasswordPanel
      messages={messages}
      return_to={return_to}
      dir={dir}
      locale={locale}
      onNavButtonClick={onNavButtonClick}
      onChangeEmail={onEmailInputChange}
      email={email}
      prev={PASSWORD_SCREEN}
    />
  );
};

export default LoginForm;
