import { Row } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGLogout } from '_Icons/menu';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { Button, BUTTON_THEME } from '_Elements/button/v2';

const PasswordSent = (props) => {
  const { email, return_to, messages } = props;

  let link = '/login';
  if (email) link = `${link}?email=${encodeURIComponent(email)}`;
  if (return_to) link = email
    ? `${link}&return_to=${encodeURIComponent(return_to)}`
    : `${link}?return_to=${encodeURIComponent(return_to)}`;

  return (
    <>
      <Row center>
        <SVGLogo
          size={64}
        />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H style={{ textAlign: 'center' }} textSize={HEADING_SIZES.H5}>{messages['password_created']}</H>
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <Button
          trailingIcon={<SVGLogout size={22} />}
          rounded
          theme={BUTTON_THEME.PRIMARY}
          href={link}
        >
          {messages['log_in']}
        </Button>
      </Row>
    </>
  );
};

export default PasswordSent;
