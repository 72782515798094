import { Row } from '_Elements/layout';
import { SVGLogo } from '_Icons';
import { SVGWand } from '_Icons/main';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';

const MagicLinkSent = (props) => {
  const { email, messages } = props;

  return (
    <>
      <Row center>
        <SVGLogo size={64} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5}>{messages['magic_link_sent']}</H>
      </Row>
      <Row center style={{ marginBlockStart: 24 }}>
        <P textSize={PARAGRAPH_SIZES.P1}>{email}</P>
      </Row>
      <Row center style={{ marginBlockStart: 42 }}>
        <SVGWand size={32} color={SYSTEM_COLOR.CHART.RED} />
      </Row>
      <Row center style={{ marginBlockStart: 42 }}>
        <P textSize={PARAGRAPH_SIZES.P2}>{messages['close_page_now']}</P>
      </Row>
      <Row center>
        <P textSize={PARAGRAPH_SIZES.P2} style={{ textAlign: 'center' }}>{messages['didnt_get_a_link']}</P>
      </Row>
    </>
  );
};

export default MagicLinkSent;
