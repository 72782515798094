import { useCallback } from 'react';
import { Row, RowItem } from '_Elements/layout';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { ToolTip, TOOLTIP_SIZE } from '_Elements/tool-tip';
import { Link } from '_Elements/text';

import { sendMagicLink } from './Utils';

const MagicLink = props => {
  const { messages, email, locale, return_to, setState } = props;

  const magicLink = useCallback(
    async ({ email, locale, return_to }) => {
      try {
        const data = await sendMagicLink({ email, locale, return_to });
        const { status, error } = data;

        if (status != 200) return { error };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const onClickMagicLink = useCallback(
    async () => {
      setState({ error: undefined });
      const { error: magicLinkError, location } = await magicLink({ email, locale, return_to });
      if (magicLinkError) return setState({ error: magicLinkError });
      metrics.track('Sign In - Magic Link - Send magic link button clicked');
      return window.location.href = location;
    },
    [setState, email, locale, return_to]
  );

  return (
    <Row spacing={8}>
      <RowItem>
        <Link
          className={'login-portal__link'}
          style={{ fontSize: 14 }}
          openInNewTab={false}
          color={SYSTEM_COLOR.PRIMARY}
          onClick={onClickMagicLink}
        >
          {messages['send_magic_link']}
        </Link>
      </RowItem>
      <RowItem>
        <ToolTip
          style={{ color: SYSTEM_COLOR.TEXT_BLACK }}
          size={TOOLTIP_SIZE.SMALL}
          content={messages['magic_link_help']}
        />
      </RowItem>
    </Row>
  );
};

export default MagicLink;
