import { useCallback } from 'react';
import { Row, RowItem } from '_Elements/layout';
import { SVGLogo, SVGArrowSolid } from '_Icons';
import { SVGLogout } from '_Icons/menu';
import { H, HEADING_SIZES } from '_Elements/text/v2';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { Button, BUTTON_THEME } from '_Elements/button/v2';
import { Link } from '_Elements/text';

import EmailPassCodeBox from './EmailPassCodeBox';
import PasswordBox from './PasswordBox';
import MagicLink from './MagicLink';
import { login, INPUT_TYPE_PASSWORD, INPUT_TYPE_TEXT } from './Utils';

const CombinedLoginPanel = (props) => {
  const {
    loading,
    messages,
    return_to,
    dir,
    locale,
    onNavButtonClick,
    onChangeEmail,
    onChangePassword,
    onClickForgotPassword,
    email,
    password,
    inputType,
    prev,
    error,
    setState,
  } = props;

  const onIconClick = useCallback(
    () => {
      setState({ inputType: inputType == INPUT_TYPE_PASSWORD ? INPUT_TYPE_TEXT : INPUT_TYPE_PASSWORD });
      const toggleType = inputType == INPUT_TYPE_PASSWORD ? 'Show' : 'Hide';
      metrics.track(`Sign in - Password - ${toggleType} password clicked`);
    },
    [setState, inputType]
  );

  const loginUser = useCallback(
    async ({ email, password, locale, return_to }) => {
      try {
        const data = await login({ email, password, locale, return_to });
        const { status, error, locked } = data;

        if (status != 200) return { error: error || messages['unknown'], locked };

        return data;
      } catch (error) {
        return Promise.resolve({ error: messages['unknown'] });
      }
    },
    [messages]
  );

  const onClick = useCallback(
    async () => {
      if (!password) return setState({ error: messages['field_not_supplied'] });

      setState({ error: undefined, loading: true });

      const { error: loginError, location, locked } = await loginUser({ email, password, locale, return_to });

      if (loginError) return setState({ error: loginError, locked, loading: false });

      return window.location.href = location;
    },
    [password, setState, messages, loginUser, email, locale, return_to]
  );

  return (
    <div className={'login-portal__input'} data-component={'password-panel'}>
      <Row center style={{ color: SYSTEM_COLOR.PRIMARY, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Link
          className={'login-portal__link'}
          style={{ fontSize: 14 }}
          openInNewTab={false}
          color={SYSTEM_COLOR.PRIMARY}
          onClick={onNavButtonClick(prev)}
        >
          <SVGArrowSolid
            size={14}
            rotate={90}
            style={{ marginInlineEnd: 8 }}
          />
        </Link>
        <SVGLogo
          size={64}
        />
        <span style={{ width: 14, visibility: 'hidden' }} />
      </Row>
      <Row center style={{ marginBlockStart: 40 }}>
        <H textSize={HEADING_SIZES.H5}>{messages['log_in']}</H>
      </Row>
      <Row style={{ marginBlockStart: 40 }}>
        <P textSize={PARAGRAPH_SIZES.P3} style={{ marginBlockEnd: 4 }}>{messages['enter_your_email_and_password']}</P>
      </Row>
      <EmailPassCodeBox
        dir={dir}
        email={email}
        onChange={onChangeEmail}
        visible={true}
        combinedView={true}
      />
      <PasswordBox
        dir={dir}
        inputType={inputType}
        password={password}
        onChange={onChangePassword}
        onClick={onClick}
        onIconClick={onIconClick}
      />
      <Row justifyContent={'space-between'} style={{ alignItems: 'start', marginBlockStart: 4, minHeight: 65 }}>
        <RowItem style={{ maxWidth: '70%', marginInlineEnd: 8 }}>
          <Row style={{ alignItems: 'start' }}>
            {error && (
              <P textSize={PARAGRAPH_SIZES.P3} style={{ color: SYSTEM_COLOR.CHART.RED }}>{error}</P>
            )}
          </Row>
        </RowItem>
        <RowItem style={{ marginBlockStart: 4 }}>
          <Link
            className={'login-portal__link'}
            style={{ fontSize: 10 }}
            openInNewTab={false}
            onClick={onClickForgotPassword}
          >
            {messages['forgot_password_link']}
          </Link>
        </RowItem>
      </Row>
      <Row justifyContent={'space-between'} style={{ marginBlockStart: 12 }}>
        <RowItem>
          <MagicLink
            messages={messages}
            email={email}
            locale={locale}
            return_to={return_to}
            setState={setState}
          />
        </RowItem>
        <RowItem>
          <Button
            trailingIcon={<SVGLogout size={22} />}
            rounded
            theme={BUTTON_THEME.PRIMARY}
            onClick={onClick}
            loading={loading}
          >
            {messages['log_in']}
          </Button>
        </RowItem>
      </Row>
    </div>
  );
};

export default CombinedLoginPanel;
