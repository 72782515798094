import { InputBox, INPUT_ICON } from '_Elements/input';

import { INPUT_TYPE_PASSWORD } from './Utils';

const PasswordBox = props => {
  const {
    dir,
    inputType,
    password,
    onChange,
    onClick,
    onIconClick,
    visible,
  } = props;

  const style = {
    display: visible ? 'inherit' : 'none'
  };
  return (
    <div style={style}>
      <InputBox
        name={'user[password]'}
        autoFocus={visible}
        className={'login-portal__password-input'}
        type={inputType}
        value={password}
        width={'100%'}
        onChange={onChange}
        onSubmit={onClick}
        submitOnBlur={false}
        icon={inputType == INPUT_TYPE_PASSWORD ? INPUT_ICON.EYE_VISIBLE : INPUT_ICON.EYE}
        onIconClick={onIconClick}
        ariaRequired
        autoComplete={'current-password'}
        dir={dir}
      />
    </div>
  );
};

PasswordBox.defaultProps = {
  inputType: INPUT_TYPE_PASSWORD,
  visible: true,
  autoFocus: false,
  onClick: () => {},
};

export default PasswordBox;
