import { InputBox } from '_Elements/input';
import { EMAIL_PLACEHOLDER } from './Utils';

const EmailPassCodeBox = props => {
  const {
    dir,
    email,
    onChange,
    onClick,
    visible,
    disabled,
    combinedView,
  } = props;

  const style = {
    display: visible ? 'inherit' : 'none',
    marginBlockEnd: combinedView ? 8 : 0,
  };
  return (
    <div style={style}>
      <InputBox
        name={'user[email]'}
        autoFocus={visible}
        ariaRequired
        autoComplete={'email'}
        dir={dir}
        value={email}
        width={'100%'}
        placeholder={EMAIL_PLACEHOLDER}
        onChange={onChange}
        onSubmit={onClick}
        submitOnBlur={false}
        disabled={disabled}
      />
    </div>
  );
};

EmailPassCodeBox.defaultProps = {
  visible: true,
  autoFocus: false,
  disabled: false,
  onChange: () => {},
};

export default EmailPassCodeBox;
